/* eslint-disable */
import styled from "styled-components"
import Button from "../button.js"
import tw from "tailwind.macro"

export const Layout = styled.div`
  width:100%;
  height:100%;
}
`
export const BlueLayout = styled(Layout)`
  background-color: #1183ff;
  @media only screen and (max-width: 992px) {
    margin-bottom: -30px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem 0;
    flex-wrap: wrap;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    flex-wrap: wrap;
  }
`

export const SolutionHeadding = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    img {
      margin-right: 15px;
    }
  }
`

export const WrapperSup = styled(Wrapper)`
  margin: 0 auto;
  max-width: 222px;
  @media only screen and (max-width: 768px) {
    max-width: 600px;
  }
`

export const WrapperMD3 = styled(Wrapper)`
  width: calc(100% / 3);
  max-width: unset;
  margin-top: 3rem;
  line-height: 2;

  @media only screen and (max-width: 768px) {
    width: 50%;
  }
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
`

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    padding: 0;
  }
`

export const SmallLeftWrapper = styled(LeftWrapper)`
  width: 50%;
  margin: 0;
  padding-right: 3rem;
  padding-left: 0;
`

export const LargeLeftWrapper = styled(LeftWrapper)`
  width: 70%;
  flex-direction: row;
  padding: 0;
`

export const RightWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`
export const RightWrapperSup = styled(RightWrapper)`
  max-width: 325px;
  margin: 3rem auto;
  padding-top: 4.5rem;

  p:first-child {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
  p:last-child {
    width: 85%;
  }

  @media only screen and (max-width: 768px) {
    max-width: none;
    margin: 1rem auto;
    padding-top: 0;
    p:first-child {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    p:last-child {
      width: 100%;
    }
  }
`

export const LargeRightWrapper = styled(RightWrapper)`
  width: 50%;
`

export const SmallRightWrapper = styled(RightWrapper)`
  width: 13%;
`

export const PhotosContainerLeft = styled.div`
  ${tw`flex flex-wrap -mx-px overflow-visible md:-mx-2`}
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  padding-left: 100px;

  @media only screen and (max-width: 768px) {
    z-index: 1;
    margin: 3rem 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
`
export const ImgBaner = styled.div`
  border: 1px solid #fff;
  border-radius: 30px;
  width: 215px;
  height: 122px;
  text-align: center;
  position: absolute;
  left: 17.5%;
  top: 40%;
  background-color: #fff;
  color: #63676f;
  font-size: 0.875rem;
  box-shadow: 1px 0px 4px #979797;
`

export const PhotosContainerRight = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: static;
  img {
    max-width: 685px;
    // margin-top: 5rem;
    // position: relative;
  }
  @media only screen and (max-width: 1200px) {
    img {
      margin-top: 0px;
      max-width: 100%;
    }
  }

  @media (min-width: 769px) {
    width: 100%;
    position: absolute;
    z-index: 99;
    left: 0;
    top: 35px;
  }
`

export const Title = styled.div`
  font-size: 1.9rem;
  font-weight: 600;
  width: 85%;
  margin-bottom: 1rem;
`
export const TitleBlock = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #0f1722;
`

export const SubTitle = styled.div`
  font-size: 1rem;
  color: #0f1722;
  font-weight: 600;
  margin-bottom: 0.5rem;
`
export const Imglogo = styled.img`
  max-width: 75px;
  max-height: 69px;
  width: 100%;
  margin-right: 40px;
`

export const Note = styled.p`
  justify-content: center;
  align-items: center;
  color: #63676f;
  line-height: 22px;
  font-size: 0.875rem;
  font-weight: 400;
  height: auto;

  @media only screen and (max-width: 1024px) {
    left: 50px;
  }

  @media only screen and (max-width: 768px) {
    margin: 1rem auto 0 auto;
  }
`
export const NoteSup = styled(Note)`
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`
export const BannerNote = styled(Note)`
  margin: 1.5rem 0;
  color: white;
  font-size: 1rem;
  color: white;
  font-size: 1rem;
  line-height: 30px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`

export const Br = styled.div`
  margin: 1rem auto;
`
export const Line = styled.div`
  border-top: 1px solid #dfe2e4;
`

const CustomedButton = styled(Button)`
  white-space: nowrap;
  font-weight: bold;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 260px;
}`

export const ButtonWhite = styled(CustomedButton)`
  background: white;
  color: #00a1ff;
`

export const ButtonBlue = styled(CustomedButton)`
  background: white;
  color: #00a1fe;
  border: 1px solid #00a1fe;
  box-shadow: 1px 1px 2px 1px #d6d6d6;
`

export const ButtonBlack = styled(CustomedButton)`
  max-width: 160px;
  background: #0e1621;
  color: white;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
`

export const SolutionItem = styled.div`
  display: flex;
`

export const Partners = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0 10px;
    img {
      max-width: 150px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`
